import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { InView } from "react-intersection-observer"

import "./styles.scss"

const AwardsCarousel = ({ children, screenWidth }) => {
  const sliderRef = useRef(null)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    document.addEventListener("keydown", handleKeyClicking)
    return () => {
      document.removeEventListener("keydown", handleKeyClicking)
    }
  })

  const handleKeyClicking = e => {
    if (isActive) {
      let buttonPrev = document.querySelector(
        `#awards-carousel--js .slick-prev`
      )

      let buttonNext = document.querySelector(
        `#awards-carousel--js .slick-next`
      )

      if (e.keyCode === 39) {
        buttonPrev.blur()
        buttonNext.focus()
        sliderRef.current.slickNext()
      }
      if (e.keyCode === 37) {
        buttonNext.blur()
        buttonPrev.focus()
        sliderRef.current.slickPrev()
      }
    }
  }

  function onSetActiveButtons(inView) {
    if (inView) {
      setIsActive(true)
    } else setIsActive(false)
  }

  return (
    <InView onChange={inView => onSetActiveButtons(inView)}>
      {({ ref }) => (
        <div
          className="awards-carousel-container custom-buttons"
          ref={ref}
          id="awards-carousel--js"
        >
          <Slider
            ref={sliderRef}
            slidesToShow={screenWidth > 639 ? 2 : 1}
            slidesToScroll={screenWidth > 639 ? 2 : 1}
            className={"awards-carousel"}
            dots={false}
            infinite={false}
            speed={1000}
          >
            {children}
          </Slider>
        </div>
      )}
    </InView>
  )
}

export default AwardsCarousel
