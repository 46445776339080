import React, { useEffect, useRef } from "react"
import createGlobe from "cobe"
import { useSpring } from "react-spring"

import * as styles from "./globe.module.scss"

const Globe = () => {
  const canvasRef = useRef()
  const pointerInteracting = useRef(null)
  const pointerInteractionMovement = useRef(0)

  const [{ r }, start] = useSpring(() => ({
    r: 0,
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }))

  useEffect(() => {
    let phi = 0
    let width = 0
    const onResize = () =>
      canvasRef.current && (width = canvasRef.current.offsetWidth)
    window.addEventListener("resize", onResize)
    onResize()
    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 1,
      width: width,
      height: width,
      phi: 0,
      theta: 0.3,
      dark: 1,
      diffuse: 1,
      mapSamples: 16000,
      mapBrightness: 1.2,
      baseColor: [1, 1, 1],
      markerColor: [0.984, 0.392, 0.082],
      glowColor: [1.2, 1.2, 1.2],
      markers: [
        { location: [38.907265, -77.036559], size: 0.08 },
        { location: [52.372208, 4.902534], size: 0.08 },
        { location: [34.06516, -118.362484], size: 0.08 },
        { location: [42.296063, -71.104888], size: 0.08 },
        { location: [24.684909, 46.679637], size: 0.08 },
        { location: [45.808205, 15.971904], size: 0.05 },
        { location: [45.309408, -92.228424], size: 0.08 },
        { location: [34.016305, -118.494232], size: 0.08 },
        { location: [37.768073, -122.417175], size: 0.08 },
        { location: [27.769371, -97.496513], size: 0.08 },
        { location: [55.674198, 12.57531], size: 0.08 },
        { location: [52.518369, 13.407797], size: 0.05 },
        { location: [33.519108, -86.83848], size: 0.05 },
        { location: [39.732546, -104.954003], size: 0.05 },
        { location: [37.979194, 23.730099], size: 0.08 },
        { location: [51.501927, -0.102641], size: 0.08 },
        { location: [46.534531, 6.588672], size: 0.08 },
        { location: [50.93693, 6.978125], size: 0.05 },
        { location: [39.98072, -82.607125], size: 0.05 },
        { location: [48.050271, 8.458575], size: 0.05 },
        { location: [43.644381, -79.403821], size: 0.05 },
        { location: [39.82353, -7.468615], size: 0.05 },
        { location: [6.522787, 3.348486], size: 0.08 },
        { location: [37.584741, -122.381488], size: 0.05 },
        { location: [52.515502, 13.40233], size: 0.05 },
        { location: [26.450343, -80.079815], size: 0.08 },
        { location: [43.360743, -2.996972], size: 0.05 },
        { location: [19.106186, 72.864002], size: 0.08 },
        { location: [46.528934, 6.631047], size: 0.05 },
        { location: [40.85955, 14.24472], size: 0.05 },
        { location: [-33.890941, 151.192613], size: 0.08 },
        { location: [40.721178, -73.993061], size: 0.05 },
        { location: [59.333624, 18.057187], size: 0.05 },
        { location: [52.225328, 21.009293], size: 0.05 },
        { location: [48.854206, 2.34139], size: 0.05 },
      ],
      onRender: state => {
        // This prevents rotation while dragging
        if (!pointerInteracting.current) {
          // Called on every animation frame.
          // `state` will be an empty object, return updated params.
          phi += 0.00075
        }
        state.phi = phi + r.getValue()
        state.width = width
        state.height = width
      },
    })
    setTimeout(() => (canvasRef.current.style.opacity = "1"))
    return () => globe.destroy()
  }, []) // eslint-disable-line

  return (
    <div
      className={styles.globeWrapper}
      style={{
        aspectRatio: 1,
      }}
    >
      <canvas
        ref={canvasRef}
        onPointerDown={e => {
          pointerInteracting.current =
            e.clientX - pointerInteractionMovement.current
          canvasRef.current.style.cursor = "grabbing"
        }}
        onPointerUp={() => {
          pointerInteracting.current = null
          canvasRef.current.style.cursor = "grab"
        }}
        onPointerOut={() => {
          pointerInteracting.current = null
          canvasRef.current.style.cursor = "grab"
        }}
        onMouseMove={e => {
          if (pointerInteracting.current !== null) {
            const delta = e.clientX - pointerInteracting.current
            pointerInteractionMovement.current = delta
            start({
              r: delta / 200,
            })
          }
        }}
        onTouchMove={e => {
          if (pointerInteracting.current !== null && e.touches[0]) {
            const delta = e.touches[0].clientX - pointerInteracting.current
            pointerInteractionMovement.current = delta
            start({
              r: delta / 100,
            })
          }
        }}
      />
    </div>
  )
}

export default Globe
