import React from "react"

import {
  CLUTCH_URL,
  GOODFIRMS_URL,
  DESIGNRUSH_URL,
  TOPDEVELOPERS_URL,
  TECHREVIEWERS_URL,
  ACIETY_URL,
  IMG,
} from "../const"

export const LIST_OPTIONS = [
  {
    id: 1,
    title: "Extensive expertise in dashboard creation",
    text:
      "When creating informational dashboards, our main focus is on high-loaded dashboards with modern infographics. Our employees have worked for well-known companies such as Compuzz, HP, Pearson, Adidas, and others. We help you visualize your data and make the right decisions based on real-time metrics.",
  },
  {
    id: 2,
    title: "MVP development experts",
    text:
      "For the last 9+ years, we have worked on MVP projects over 500 different cases. Our experts have created a seamless process with the right tools to ensure we achieve results quickly and efficiently.",
  },
  {
    id: 3,
    title: "An international company in fast-growing regions",
    text:
      "We are located in fast-growing regions of Europe and the United States (Lviv, Łódź, Washington, DC), and work remotely with staff from 7 countries (Poland, Ukraine, Turkey, and other countries). Lviv is the geographical center of Europe, very close to Poland and Western Europe. More than 492 IT companies have decided to open their offices here, and today, more than 26000 IT specialists work here. Local universities graduate more than 4100 students in the field of IT every year. Lviv is considered to be the center of the IT industry in Ukraine. Łódź is located in the center of Poland, which makes it a convenient logistics hub in the city with more than 150 IT companies that currently employ more than 55,000 IT professionals. Local universities graduate more than 15000 students in the field of IT. We also have a representative office in Washington, DC, so we are open to meetings in North America.",
  },
  {
    id: 4,
    title: "The full package of services for businesses",
    text:
      "We take care of all aspects of software development including UI/UX design, product design and architecture, WebApp development, Quality Assurance, project management, and project support.",
  },
  {
    id: 5,
    title: "Focus on your business success",
    text:
      "At LaSoft, our teams are unwavering in their dedication to the success of our customers' projects. Our core foundation lies in producing high-quality products characterized by exceptional design and code. Our commitment extends beyond this foundational aspect as well. We embrace a holistic approach to collaboration, where we actively pursue strategies to enhance profitability, optimize expenditures, and expand user engagement. This distinctive approach is what sets LaSoft apart from competitors.",
  },
  {
    id: 6,
    title: "In-house product design expertise",
    text:
      "The focal point of our journey towards enhancing customers' digital solutions for increased capitalization and user expansion is our internal team of product managers. Our dedicated product department empowers our clients to implement a data-driven and test-based approach, ensuring strategic and effective product adaptations that align seamlessly with objectives, whether in the short-term or long-term perspective.",
  },
  {
    id: 7,
    title: "Test-Driven Approach",
    text:
      "Our methodology revolves around the systematic use of tests to validate hypotheses rooted in thorough research and data analysis, all aimed at enhancing our clients' products. This approach enables us to pinpoint the most efficient solutions to challenges while conserving both our company's and our client's resources. In doing so, we consistently contribute to our clients' success.",
  },
]

export const AWARDS = [
  {
    id: 1,
    title: "Top BTB company on Clutch",
    image: "award-clutch-top-developers-ukraine-2020",
    altText: "Top BTB company on Clutch",
    url: CLUTCH_URL,
  },
  {
    id: 2,
    title: "Top Web & Python developers company on TopDevelopers.co",
    image: "award-topdevelopers-top-python-developers-2020",
    altText: "Top Web & Python developers company on TopDevelopers.co",
    url: TOPDEVELOPERS_URL,
  },
  {
    id: 3,
    title: <>Top Web Design for Small Business on&nbsp;Techreviewer.co</>,
    image: "award-topreviewer-top-web-design-for-small-business-2021",
    altText: "Top Web design for Small Business on Techreviewer.co",
    url: TECHREVIEWERS_URL,
  },
  {
    id: 4,
    title: "Top Web Development Company on Goodfirms",
    image: "award-goodfirms-top-web-development-company",
    altText: "Top Web Development Company on Goodfirms",
    url: GOODFIRMS_URL,
  },
  {
    id: 5,
    title: "Best Ruby on Rails agency on DesignRush",
    image: "award-designrush-best-ruby-on-rails-agency",
    altText: "Best Ruby on Rails agency on DesignRush",
    url: DESIGNRUSH_URL,
  },
  {
    id: 6,
    title: "System Architecture Development",
    image: "award-system-architecture-development",
    altText: "System Architecture Development",
    url: ACIETY_URL,
  },
]

export const Picture = ({
  number,
  width,
  height,
  altText,
  desktopBreakpoint,
}) => {
  const imgUrl = `${IMG.ABOUT}/team/${number}/team_${number}`

  return (
    <picture>
      <source
        media="(max-width: 414px)"
        srcSet={`
          ${imgUrl}_mobile.avif,
          ${imgUrl}_mobile@2x.avif 2x,
          ${imgUrl}_mobile@3x.avif 3x`}
        type="image/avif"
      />
      <source
        media="(max-width: 414px)"
        srcSet={`
          ${imgUrl}_mobile.webp,
          ${imgUrl}_mobile@2x.webp 2x,
          ${imgUrl}_mobile@3x.webp 3x`}
        type="image/webp"
      />
      <source
        media="(max-width: 414px)"
        srcSet={`
          ${imgUrl}_mobile.jpg,
          ${imgUrl}_mobile@2x.jpg 2x,
          ${imgUrl}_mobile@3x.jpg 3x`}
      />
      <source
        media={`(min-width: 415px) and (max-width: ${
          +desktopBreakpoint - 1
        }px)`}
        srcSet={`
          ${imgUrl}_tablet.avif,
          ${imgUrl}_tablet@2x.avif 2x,
          ${imgUrl}_tablet@3x.avif 3x`}
        type="image/avif"
      />
      <source
        media={`(min-width: 415px) and (max-width: ${
          +desktopBreakpoint - 1
        }px)`}
        srcSet={`
          ${imgUrl}_tablet.webp,
          ${imgUrl}_tablet@2x.webp 2x,
          ${imgUrl}_tablet@3x.webp 3x`}
        type="image/webp"
      />
      <source
        media={`(min-width: 415px) and (max-width: ${
          +desktopBreakpoint - 1
        }px)`}
        srcSet={`
          ${imgUrl}_tablet.jpg,
          ${imgUrl}_tablet@2x.jpg 2x,
          ${imgUrl}_tablet@3x.jpg 3x`}
      />
      <source
        media={`(min-width: ${desktopBreakpoint}px)`}
        srcSet={`
          ${imgUrl}_desktop.avif,
          ${imgUrl}_desktop@2x.avif 2x,
          ${imgUrl}_desktop@3x.avif 3x`}
        type="image/avif"
      />
      <source
        media={`(min-width: ${desktopBreakpoint}px)`}
        srcSet={`
          ${imgUrl}_desktop.webp,
          ${imgUrl}_desktop@2x.webp 2x,
          ${imgUrl}_desktop@3x.webp 3x`}
        type="image/webp"
      />
      <source
        media={`(min-width: ${desktopBreakpoint}px)`}
        srcSet={`
          ${imgUrl}_desktop.jpg,
          ${imgUrl}_desktop@2x.jpg 2x,
          ${imgUrl}_desktop@3x.jpg 3x`}
      />

      <img
        alt={altText}
        src={`${imgUrl}_desktop.jpg`}
        loading="lazy"
        width={width}
        height={height}
      />
    </picture>
  )
}
