// extracted by mini-css-extract-plugin
export var aboutAwards = "about-module--aboutAwards--bff1a";
export var aboutAwards__wrapper = "about-module--aboutAwards__wrapper--2d028";
export var aboutBlock = "about-module--aboutBlock--adb87";
export var aboutMap = "about-module--aboutMap--65f28";
export var aboutMap__content = "about-module--aboutMap__content--40edf";
export var aboutSteps = "about-module--aboutSteps--7009c";
export var aboutSteps__row1 = "about-module--aboutSteps__row1--09c33";
export var aboutSteps__row2 = "about-module--aboutSteps__row2--ffe5a";
export var aboutUs = "about-module--aboutUs--200dd";
export var aboutUs__text = "about-module--aboutUs__text--b8219";
export var awardCard = "about-module--awardCard--99458";
export var billetShadow = "about-module--billet-shadow--71916";
export var colorScheme__background__grayPale = "about-module--colorScheme__background__gray-pale--8ec80";
export var counter = "about-module--counter--87b17";
export var counter__wrapper = "about-module--counter__wrapper--81a25";
export var globeBox = "about-module--globeBox--a8011";
export var heading = "about-module--heading--25212";
export var heading__grid = "about-module--heading__grid--31f9b";
export var heading__imgWrapper = "about-module--heading__imgWrapper--102de";
export var icomoon = "about-module--icomoon--135ca";
export var imgBlock = "about-module--imgBlock--b6adc";
export var imgCityBackground = "about-module--imgCityBackground--43706";
export var large = "about-module--large--d241b";
export var leftText = "about-module--leftText--5b293";
export var logoBlock = "about-module--logoBlock--bccc2";
export var lowercase = "about-module--lowercase--e160d";
export var number = "about-module--number--05673";
export var ourStory = "about-module--ourStory--19041";
export var ourStory__bg = "about-module--ourStory__bg--887f3";
export var ourStory__title = "about-module--ourStory__title--d8d83";
export var pic__1 = "about-module--pic__1--f0398";
export var pic__2 = "about-module--pic__2--d986c";
export var rightText = "about-module--rightText--0bef7";
export var textBlock = "about-module--textBlock--4207e";
export var transition = "about-module--transition--0d70c";
export var transitionBackground = "about-module--transition-background--b1282";
export var transitionReverse = "about-module--transition-reverse--3f4ba";
export var twoColumn = "about-module--twoColumn--13851";
export var uppercase = "about-module--uppercase--25062";